import React, { useState, useEffect } from 'react';
import './App.css';
import { Amplify } from 'aws-amplify';
import { awsExports } from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Auth } from "aws-amplify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIcons, faStore, faBed, faLandmarkDome, faKitMedical, faUserDoctor, faHandHoldingHeart, faUtensils, faMountainSun, faBuilding, faUserGraduate, faVanShuttle, faCoins, faWheatAwn, faIndustry, faBuildingFlag } from '@fortawesome/free-solid-svg-icons'


Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID
  }
});

function App() {
  const [jwtToken, setJwtToken] = useState('');

  useEffect(() => {
    fetchJwtToken();
  }, []);

  const fetchJwtToken = async () => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      setJwtToken(token);
    } catch (error) {
      console.log('Error fetching JWT token:', error);
    }
  };

  return (
    <Authenticator initialState='signIn'
      components={{
        SignUp: {
          FormFields() {

            return (
              <>
                <Authenticator.SignUp.FormFields />

                {/* Custom fields for given_name and family_name */}
                <div><label>First name</label></div>
                <input
                  type="text"
                  name="given_name"
                  placeholder="Please enter your first name"
                />
                <div><label>Last name</label></div>
                <input
                  type="text"
                  name="family_name"
                  placeholder="Please enter your last name"
                />
                <div><label>Email</label></div>
                <input
                  type="text"
                  name="email"
                  placeholder="Please enter a valid email"
                />
              </>
            );
          },
        },
      }}
      services={{
        async validateCustomSignUp(formData) {
          if (!formData.given_name) {
            return {
              given_name: 'First Name is required',
            };
          }
          if (!formData.family_name) {
            return {
              family_name: 'Last Name is required',
            };
          }
          if (!formData.email) {
            return {
              email: 'Email is required',
            };
          }
        },
      }}
      hideSignUp={true}
    >
      {({ signOut, user }) => (

        <ul>
          <li>
            <a href="https://arcg.is/1bei400"><FontAwesomeIcon icon={faIcons} /> Entertainment, Sports, Arts & Culture</a>
          </li>
          <li>
            <a href="https://arcg.is/Ka9W0"><FontAwesomeIcon icon={faStore} /> Shops</a>
          </li>
          <li>
            <a href="https://arcg.is/1iifbz"><FontAwesomeIcon icon={faBed} /> Accomodation</a>
          </li>
          <li>
            <a href="https://arcg.is/1Krf11"><FontAwesomeIcon icon={faLandmarkDome} /> Historic</a>
          </li>
          <li>
            <a href="https://arcg.is/18eb1P"><FontAwesomeIcon icon={faKitMedical} /> Healthcare</a>
          </li>
          <li>
            <a href="https://arcg.is/059e9r"><FontAwesomeIcon icon={faUserDoctor} /> Healthcare Proffessional</a>
          </li>
          <li>
            <a href="https://arcg.is/GzuK8"><FontAwesomeIcon icon={faHandHoldingHeart} /> Public Services</a>
          </li>
          <li>
            <a href="https://arcg.is/0HyTj4"><FontAwesomeIcon icon={faUtensils} /> Food & Beverages</a>
          </li>
          <li>
            <a href="https://arcg.is/1WXiTS0"><FontAwesomeIcon icon={faMountainSun} /> Attraction & Tourism</a>
          </li>
          <li>
            <a href="https://arcg.is/1eymeb"><FontAwesomeIcon icon={faBuilding} /> Office</a>
          </li>
          <li>
            <a href="https://arcg.is/1TqnyL0"><FontAwesomeIcon icon={faUserGraduate} /> Education</a>
          </li>
          <li>
            <a href="https://arcg.is/5PS41"><FontAwesomeIcon icon={faVanShuttle} /> Transportation</a>
          </li>
          <li>
            <a href="https://arcg.is/0PqWmP0"><FontAwesomeIcon icon={faCoins} /> Financial</a>
          </li>
          <li>
            <a href="https://arcg.is/KzGby"><FontAwesomeIcon icon={faWheatAwn} /> Agricultural</a>
          </li>
          <li>
            <a href="https://arcg.is/1Pe1TH0"><FontAwesomeIcon icon={faIndustry} /> Factory</a>
          </li>
          <li>
            <a href="https://arcg.is/1Cqu8n"><FontAwesomeIcon icon={faBuildingFlag} /> Government, Diplomatic, Religious & Political Office</a>
          </li>
          <li onClick={signOut} style={{ backgroundColor: '#047D95' }} >
            <a href="javascript:void(0);" style={{ color: '#ffffff' }}>Log out</a>
          </li>
        </ul>
      )}
    </Authenticator>
  );
}

export default App;
